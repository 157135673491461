@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.eot");
  src: url("../../public/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-bold-webfont/basiersquare-bold-webfont.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.eot");
  src: url("../../public/fonts/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-medium-webfont/basiersquare-medium-webfont.eot");
  src: url("../../public/fonts/Basier-Square-medium-webfont/basiersquare-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-medium-webfont/basiersquare-medium-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-medium-webfont/basiersquare-medium-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-medium-webfont/basiersquare-medium-webfont.ttf")
      format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.eot");
  src: url("../../public/fonts/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.ttf")
      format("truetype");
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.eot");
  src: url("../../public/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-regular-webfont/basiersquare-regular-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.eot");
  src: url("../../public/fonts/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.eot");
  src: url("../../public/fonts/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../../public/fonts/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.eot");
  src: url("../../public/fonts/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.woff")
      format("woff"),
    url("../../public/fonts/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
}
